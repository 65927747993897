@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700;900&family=Roboto:wght@900&display=swap');

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Jost', sans-serif;
    background-color: #dedede;
    scroll-behavior: smooth;
}

ul {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}
a {
    text-decoration: none;
    color: inherit;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
img {
    object-fit: cover;
    object-position: center;
}

textarea::-webkit-scrollbar {
    width: 0;
}
.main-page-container {
    display: flex;
}
.main-page-image {
    height: 100vh;
    width: 50%;
    position: relative;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5);;
}
.main-page-image img {
    height: 100%;
    width: 100%;
    display: block;
}
.main-page-image .names {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}
.main-page-image h1 {
    font-size: 60px;
    color: white;
    text-align: center;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
}
.main-page-image h2 {
    font-size: 36px;
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
    text-align: center;
    font-weight: 300;
}
.log-form {
    padding: 40px 80px;
    background-color: #fbfbfb;
    margin: auto;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
}
.log-form h2 {
    font-size: 36px;
}
.form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    font-size: 20px;
}

.form button {
    margin-top: 20px;
    margin: 10px 0 0;
    font-family: 'Jost', sans-serif;
    font-weight: 700;
    color: white;
    padding: 10px;
    background-color: #218C74;
    border: none;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.2), -1px 0px rgba(0, 0, 0, 0.2);
    font-size: 20px;
    cursor: pointer;
}
.form input, .form select {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    padding: 10px;
    font-size: 20px;
    width: 100%;
}
.grave-form input {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    padding: 5px;
    font-size: 20px;
}
.grave-form input[type="file"] {
    font-size: 20px;
}
.form label {
    width: 100%;
}
.grave-form {
    padding: 20px;
    background-color: #fbfbfb;
    margin-bottom: 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
    position: relative;
}
.grave-form h3 {
    font-size: 28px;
}
.people { 
    flex: 100%;
}
.people .single-person {
    padding: 10px;
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    justify-content: space-around;
    background-color: rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 20%);
}
.grave-info { 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    column-gap: 2%;
}
.grave-info h3 {
    font-size: 28px;
}
.grave-info .column {
    flex: 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.grave-info .form-field {
    display: flex;
    align-items: center;
}
.grave-info .form-field label {
    display: block;
    width: 30%;
    font-weight: 700;
}
.grave-info .form-field input {
    width: 70%;
}
.grave-info textarea {
    padding: 10px;
    resize: none;
    font-size: 20px;
    font-family: 'Jost', sans-serif;
    outline: none;
    width: 70%;
    height: 120px;
}
.grave-images {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}
.grave-images .grave-image {
    flex: 49%;
    overflow: hidden;
    height: 520px;
    position: relative;
    text-align: center;
}

nav.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: #485460;
    display: inline-flex;
    line-height: 80px;
    color: white;
    font-weight: 300;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
    text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
    justify-content: center;
    z-index: 3000;
}

nav.main ul {
    display: inline-flex;
    font-size: 24px;
    column-gap: 40px;
    margin: auto;
    margin-right: 50px;
    /* width: 200px; */
}

nav.main h2 {
    font-size: 32px;
    font-weight: 300;
    width: max-content;
    margin: auto;
    margin-left: 50px;
}

nav.side {
    width: 120px;
    height: calc(100vh - 80px);
    position: fixed;
    bottom: 0;
    z-index: 2;
    background-color: #303841;
    color: white;
    font-weight: 300;
    text-align: center;
    box-shadow: 1px 0px 2px rgb(0 0 0 / 50%);
}
nav.side ul {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-shadow: 1px 1px black;
}

nav.side ul a {
    padding: 20px 0;
    border-bottom: 1px solid rgb(0 0 0 / 20%);
    width: 100%;
}

.page-container {
    padding: 100px 40px 0 200px;
    font-size: 20px;
}

.admin-info h2 {
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 700;
}
.admin-info h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 400;
}

.handled-modules {
    display: flex;
    gap: 50px;
}

.handled-modules .module {
    height: 200px;
    width: 300px;
    background-color: #fbfbfb;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 300;
}

.datepicker-container {
    width: fit-content;
    padding: 10px 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    margin-left: auto;
    margin-right: 40px;
    font-family: 'Jost', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: -120px;
}
.datepicker-container button, .query-container button, .container-intentions button, .container-cemetery button, .grave-form button {
    margin: 10px 0 0;
    font-family: 'Jost', sans-serif;
    font-weight: 700;
    color: white;
    padding: 10px;
    background-color: #218C74;
    border: none;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.2), -1px 0px rgba(0, 0, 0, 0.2);
    font-size: 20px;
    cursor: pointer;
}
.datepicker-container input, .container-intentions select {
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
}
.query-container {
    padding: 0;
    display: flex;
    column-gap: 20px;
    justify-content: center;
}
.query-container input{
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    font-family: 'Jost', sans-serif;
    width: 400px;
}

.intentions-container { 
    background: #fbfbfb; 
    padding: 20px;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
}
.intentions-container h3 {
    padding-bottom: 20px;
}
.mass {
    display: flex;
    padding: 5px 250px 5px 0;
}

.mass-time {
    min-width: 140px;
}

.mass-time input{
    font-size: 20px;
    font-family: 'Jost', sans-serif;
}

.mass-intention {
    text-align: justify;
    width: 100%;
    margin-bottom: 20px;
}
.mass-intention textarea {
    width: calc(100% - 20px);
    height: 100%;
    padding: 10px;
    resize: none;
    font-size: 20px;
    font-family: 'Jost', sans-serif;
    outline: none;
}

.to-up button {
    margin: 10px 0 0;
    font-family: 'Jost', sans-serif;
    font-weight: 700;
    color: white;
    padding: 10px;
    background-color: #218C74;
    border: none;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.2), -1px 0px rgba(0, 0, 0, 0.2);
    font-size: 20px;
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    transition: 0.2s;
    opacity: 0;
}

.intentions-container button {
    color: white;
    cursor: pointer;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.2), -1px 0px rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    font-size: 20px;
    padding: 0 10px;
}

.container-intentions {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.database-config {
    display: flex;
    flex-direction: column;
}
.database-config h2, .add-grave h2, .all-graves h2 {
    margin-bottom: 20px;
}
.database-config label {
    margin-bottom: 10px;
}
.container-calendar {
    width: 100%;
}

.add-grave {
    margin-bottom: 40px;
    flex: 50%;
}
.all-graves {
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    position: relative;
}
.all-graves .grave {
    padding: 5px 5px;
    background-color: #fbfbfb;
    margin: 10px 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-basis: calc(33% - 2em);
}
.all-graves .grave .description {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 1em;
    font-size: 24px;
}
.all-graves .grave .people {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.all-graves .grave .person {
    padding: 10px 30px;
    text-align: center;
    font-size: 20px;
}

.map {
    width: 100%;
    height: 800px;
    position: relative;
}

.search-bar {
    background-color: #fbfbfb;
    padding: 1em;
    margin-bottom: 1em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    gap: 0.7em;
    position: relative;
    flex: 30%;
}

.search-bar label {
    display: block;
}

.search-bar input {
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    width: calc(100% - 24px);
}

.search-bar button, .all-graves button {
    margin: 10px 0 0;
    font-family: 'Jost', sans-serif;
    font-weight: 700;
    color: white;
    padding: 10px;
    background-color: #218C74;
    border: none;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.2), -1px 0px rgba(0, 0, 0, 0.2);
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.search-bar p {
    margin: 0;
    color: #656565;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
}

.search-bar .form-search-element {
    width: 100%;
    line-height: 200%;
}

.container-cemetery {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}
.search-results {
    background-color: #fbfbfb;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 1em;
    flex: 5;
}
.search-results .grave {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 1em 0;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 5px 5px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
}

.search-results .grave h3 {
    flex: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
}

.search-results .people {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 55%;
}
.search-results .grave .person {
    padding: 10px 30px;
    text-align: center;
    font-size: 24px;
}
.container-search-bar {
    display: flex;
    gap: 2%;
}

.calendar-config h4 {
    margin: 0.7em 0;
}